import {reactive, toRefs,onBeforeMount, onMounted,onBeforeUnmount, defineComponent, getCurrentInstance, computed,nextTick} from 'vue';
import MessageCardUtil,{IMessageCardDataObj} from "@/views/sysviews/yunwei/message/ts/messageCardUtil";

export default defineComponent({
    name: 'messageCard',
    title: "通知公告",
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IMessageCardDataObj=reactive<IMessageCardDataObj>({
            utilInst:{}as any,
            refMap:new Map(),
            formRef:null,
            userSelRef:null,
            disabled:false,//通知公告是否可编辑控制变量
            myEditor:{},//富文本编辑对象
            corpTreeData: [],//机构级联数据
            roleData:[],//角色下拉数据
            selDisableFlag:false,//下拉select是否禁用标志
            userData:[],//页面用户下拉select数据
            sysUserData:[],//所有用户账号
            modelFrom:'',//打开通知公告详情的时候，到底是从发布通知公告列表来的呢还是从个人通知公告列表来的
            cardEngine:'',//通过cardEngine去找top组件，签收的时候，要调用top组件的方法重新查询未签收的消息数量，否则top右边的badge永远有数字
            compParams: { //卡片传给dialog的初始化参数
                modelPath: utils.Api.buildUrl("/message"),
                hsDetails: false
            },
            form: {},//表单属性
            rules: utils.UtilPub.commonFormValidRule([{title:'请输入消息标题'}])//表单验证规则
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MessageCardUtil(proxy,dataObj);
        })
        onBeforeUnmount(()=>{
            dataObj.myEditor.destroy();// 销毁编辑器
            dataObj.myEditor = null;
        })
        onMounted(()=>{
            dataObj.myEditor=utils.Tools.buildWangEditorField({editRef:dataObj.refMap.get('msgContent')})
        })

        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                let content=[] as any;
                switch (params) {
                    case 'flag':content=[{value:0,label:proxy.$t('message.msg_announcement')},{value:1,label:proxy.$t('message.msg_online')}]; break;
                    case 'type':content=[{value:1,label:proxy.$t('message.msg_system')},{value:2,label:proxy.$t('message.msg_corp')},
                        {value:3,label:proxy.$t('message.msg_role')},{value:4,label:proxy.$t('message.msg_person')}]; break;
                }
                return content;
            }
        })

        //打开弹出框的回调事件
        const beforeOpen=(data:any,addOrLoad:string,cardEngine:any)=>{
            nextTick(async ()=>{
                await dataObj.utilInst.initDataObjProp(data,addOrLoad,cardEngine);
                //以下是判断是否按角色或者用户发送消息，由于是下拉select多选，为了回显，需要把字符串分割为数组，这样才能回显正确
                if(dataObj.form.recRoleIds)dataObj.form.recRoleIds=data.data.recRoleIds.split(',');
                if(dataObj.form.recUserIds)dataObj.form.recUserIds=data.data.recUserIds.split(',');
            })
        }
        //打开dialog之前，设置请求后端的参数，这里把来源传到后端，主要是控制头部哪些按钮显示与不显示
        //从公告消息（MessageReceiveList）那里进入的时候，就不能有新增、保存等等按钮，只能决定是否显示签收按钮，
        //所以这里把modelFrom传入后台，打开卡片的时候就可以判断是否显示对应按钮了
        const setParam=(cardEngine:any)=>{
            return {modelFrom:cardEngine.engineParams.queryParam.modelFrom};
        }
        const beforeSaveHandler=()=>{
            return dataObj.utilInst.checkValidate();
        }
        //签收消息
        const receiveHandle=()=>{
            dataObj.utilInst.signMessage();
        }

        return{
            ...toRefs(dataObj),comboSelect,beforeOpen,setParam,beforeSaveHandler,receiveHandle
        }
    }
});