import BaseBean from "@/utils/BaseBean";
export interface IMessageCardDataObj {
    utilInst:MessageCardUtil
    refMap:Map<string,any>
    formRef:any
    userSelRef:any
    disabled:boolean//通知公告是否可编辑控制变量
    myEditor:any//富文本编辑对象
    corpTreeData: Array<any>//机构级联数据
    roleData:Array<any>//角色下拉数据
    selDisableFlag:boolean//下拉select是否禁用标志
    userData:Array<any>//页面用户下拉select数据
    sysUserData:Array<any>//所有用户账号
    modelFrom:any//打开通知公告详情的时候，到底是从发布通知公告列表来的呢还是从个人通知公告列表来的
    cardEngine:any//通过cardEngine去找top组件，签收的时候，要调用top组件的方法重新查询未签收的消息数量，否则top右边的badge永远有数字
    compParams:any //卡片传给dialog的初始化参数
    form: any//表单属性
    rules:any //表单验证规则
}
export default class MessageCardUtil extends BaseBean{
    public dataObj:IMessageCardDataObj;

    constructor(proxy:any,dataObj:IMessageCardDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //初始化dataObj相关属性
    public async initDataObjProp(data:any,addOrLoad:string,cardEngine:any):Promise<void>{
        if(data.data){
            this.dataObj.cardEngine=cardEngine;
            //打开通知公告详情之后，把来源赋上值(在两个列表的queryParam那里指定有modelFrom，而列表的参数又会传给dialog，dialog又会把参数传给引擎)
            this.dataObj.modelFrom=cardEngine.engineParams.queryParam.modelFrom;
            if(this.dataObj.modelFrom=='messageReceive'){//如果来源个人通知公告列表，那么禁用某些项
                this.dataObj.disabled=true;
                this.dataObj.myEditor.disable();
                this.dataObj.selDisableFlag=true;
            }

            if(data.data.type==0)this.dataObj.form.type='';//由于后台消息类别是整形，默认为0，这里需要把它赋值为空，否则下拉框显示为0

            //请求获取机构数据源
            this.dataObj.corpTreeData = await this.utils.Api.corpTreeData();
            //请求获取角色数据源
            this.dataObj.roleData=await this.utils.Api.getRoles();
            //请求获取用户数据源
            this.dataObj.sysUserData=await this.utils.Api.getUsers();
            this.dataObj.userData=this.dataObj.sysUserData;

            //后端发过来的是字符串形式，前端级联控件和下拉控件需要用json的方式才能正确显示
            if(this.utils.UtilPub.isNotEmpty(data.data.recCorpIds))this.dataObj.form.recCorpIds=JSON.parse(data.data.recCorpIds);
            if(this.utils.UtilPub.isNotEmpty(data.data.recRoleIds))this.dataObj.form.recRoleIds=JSON.parse(data.data.recRoleIds);

            if('/load'==addOrLoad){//如果不是新增。需要把通告内容赋值上
                if(this.dataObj.myEditor)this.dataObj.myEditor.txt.html(data.data.content) //设置编辑器内容
            }
        }
    }
    //消息类别下拉select发生变化事件
    public async msgTypeChangeHandler(val:number,that:MessageCardUtil):Promise<void>{
        if(val==1){//如果为1，表示要发送在线消息，只显示在线用户
            this.dataObj.userData=await this.utils.Api.getOnlineUsers();
        }else{
            this.dataObj.userData=this.dataObj.sysUserData;
        }
    }
    //预览消息
    public preViewMsg():void{
        this.dataObj.form.content=this.dataObj.myEditor.txt.html();
        if(!this.dataObj.form.title || !this.dataObj.form.content){
            this.utils.Tools.info({message:this.proxy.$t('message.title_content_null_tip')});
        }else{
            let params = Object.assign({duration:0}, {title:this.dataObj.form.title,message:this.dataObj.form.content});
            this.utils.Tools.info(params);
        }
    }
    //保存之前数据校验
    public checkValidate():boolean{
        //保存之前，校验整个保存数据是否合法
        if(this.dataObj.form.flag==0){
            if(this.utils.UtilPub.isEmpty(this.dataObj.form.type)){
                this.utils.Tools.info({message:this.proxy.$t('message.form.type_placeholder')});return false;
            }
            if(this.dataObj.form.type==2 && this.utils.UtilPub.isEmpty(this.dataObj.form.recCorpIds)){
                this.utils.Tools.info({message:this.proxy.$t('message.form.recCorpIds_placeholder')}); return false;
            }
            if(this.dataObj.form.type==3 && this.utils.UtilPub.isEmpty(this.dataObj.form.recRoleIds)){
                this.utils.Tools.info({message:this.proxy.$t('message.form.recRoleIds_placeholder')}); return false;
            }
            if(this.dataObj.form.type==4 && this.utils.UtilPub.isEmpty(this.dataObj.form.recUserIds)){
                this.utils.Tools.info({message:this.proxy.$t('message.form.recUserIds_placeholder')}); return false;
            }
        }
        this.dataObj.form.content=this.dataObj.myEditor.txt.html();
        if(this.utils.UtilPub.isEmpty(this.dataObj.form.content)){
            this.utils.Tools.info({message:this.proxy.$t('message.form.content_placeholder')}); return false;
        }
        if(this.dataObj.form.recUserIds)this.dataObj.form.recUserIds=this.dataObj.form.recUserIds.join(',');
        return true;
    }
    //签收消息
    public signMessage():void{
        this.utils.Tools.configBox({
            message:this.proxy.$t('message.sign_sure_tip'),
            sureFn: async ()=> {
                let res = await this.utils.Api.messageSign({id: this.dataObj.form.id});
                if(res.result)  {
                    this.utils.Tools.success();

                    //top组件头部的消息数量需要重新加载
                    await this.dataObj.cardEngine.engineParams.ownerComp.mainComp.topRef.utilInst.queryNotQsMsgNum();
                    //重新加载该消息表单，主要是更新卡片头部的按钮
                    await this.dataObj.cardEngine.engineUtil.doAddOrLoad(this.dataObj.form.id);
                    //重新加载列表页面，更新操作列和签收状态等
                    await this.dataObj.cardEngine.engineParams.ownerComp.queryHandler()
                }
            }
        });
    }
}